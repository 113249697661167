"use strict";

document.querySelectorAll(".js-header-menu").forEach((target) => {
  target.addEventListener("click", () => {
    document.querySelector(".js-header").classList.toggle("is-active");
  });
});

import { slideDown, slideUp, slideStop, isVisible } from "slide-anim";

// トリガークラス'.js-accordion-trigger'を持つ要素を取得
const accordionTrigger = document.querySelectorAll(".js-accordion-trigger");

for (let i = 0; i < accordionTrigger.length; i++) {
  // トリガーを押した時のアクション
  let accordionTargetisOpened = false;
  accordionTrigger[i].addEventListener("click", (e) => {
    // クリックされた要素（トリガー要素）を取得
    let currentElement = e.currentTarget;

    // 同じ親要素を持つ隣接した次の要素'.js-accordion-target'（展開対象の要素）を取得
    let accordionTarget = currentElement.nextElementSibling;
    if (accordionTargetisOpened) {
      //トリガーの'is-active'クラスを削除
      currentElement.classList.remove("is-active");
      slideUp(accordionTarget).then(function () {
        accordionTargetisOpened = false;
      });
    } else {
      //トリガーの'is-active'クラスを追加
      currentElement.classList.add("is-active");
      slideDown(accordionTarget).then(function () {
        accordionTargetisOpened = true;
      });
    }
  });
}
